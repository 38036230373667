import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { addHeaderStylesheet } from '@/utils/editor/theme.utils';
import { type AppConfiguration, ApplicationMode, type ApplicationTheme } from '@/models/app.model';
import { SessionStorage, setCssVar } from 'quasar';
import * as appApi from '@/services/api/app.api';
import { StorageKeys } from '@/models/storage.model';
import type { Company } from '@/models/company.model';
import { DocumentType } from '@/enums/editor/document/document.enum';
import { i18n } from '@/plugins/i18n';
import type { Font } from '@/models/editor/document/theme.model';
import type { User } from '@/models/user.model';
import type { Reseller } from '@/models/reseller.model';
import { setupInterface } from '@/utils/reseller.utils';
import { UserRoles } from '@/enums/role.enum';
import { useRoles } from '@/composables/role.composable';
import { useAuthenticationStore } from '@/stores/authentication.store';

export const useGlobalStore = defineStore('global', () => {
    const { isGranted } = useRoles();
    const authenticationStore = useAuthenticationStore();

    const headerTitle = ref('');
    const isDemo = ref(false);
    const isDrawerOpen = ref(false);
    const hideSidebarQueryParam = ref(false);

    const hasSidebar = computed(() => {
        return (
            isGranted(UserRoles.ROLE_ADMIN) ||
            authenticationStore.impersonate ||
            !appConfiguration.value?.hideLeftSidebar
        );
    });
    const sidebar = ref(true);

    const appMode = ref<ApplicationMode>(
        SessionStorage.getItem(StorageKeys.ApplicationMode) || ApplicationMode.Standalone,
    );

    const loadingProgress = ref(0);

    const isReady = ref(false);
    const applicationError = ref<string>();
    const appConfiguration = ref<AppConfiguration>();
    const applicationTheme = ref<ApplicationTheme>();
    const reseller = ref<Reseller>();
    const company = ref<Company>();
    const user = ref<User>(null);

    const extraStyles = ref<{ [link: string]: HTMLLinkElement }>({});

    const isCompanyLinkToOpinionSystem = computed(() => !!company.value?.opinionSystemId);
    const isUserLinkToOpinionSystem = computed(() => !!user.value?.opinionSystemId);
    const isLinkedToImmodvisor = computed(() => !!company.value?.immodvisorId || !!user.value?.immodvisorId);

    const products = computed(() => company.value?.products);
    const isBusinessCompany = computed(() => products.value?.includes(DocumentType.AvmBusiness));

    const isNotary = computed(() => company.value?.brand?.notary);

    const isStandalone = computed(() => appMode.value === ApplicationMode.Standalone);

    watch(
        appMode,
        () => {
            // ApplicationMode should always be stored in session
            SessionStorage.set(StorageKeys.ApplicationMode, appMode.value);
        },
        { immediate: true },
    );

    function setTitle(_headerTitle: string) {
        headerTitle.value = _headerTitle;
    }

    function addStylesheet(font: Font) {
        if (!extraStyles.value.hasOwnProperty(font.name)) {
            extraStyles.value[font.name] = addHeaderStylesheet(font.uri);
        }
    }

    function setDemoMode(demo: boolean) {
        isDemo.value = demo;
    }

    async function fetchCompany() {
        return (company.value = await appApi.fetchCompany());
    }

    async function fetchCompanyAuth() {
        return (company.value.authKey = await appApi.fetchCompanyAuth());
    }

    async function fetchUser() {
        return (user.value = await appApi.fetchUser());
    }

    async function setResellerAppConfiguration() {
        reseller.value = await appApi.fetchReseller();
        setupInterface(reseller.value);
        return (appConfiguration.value = reseller.value.configuration);
    }

    async function fetchApplicationSettings() {
        const [apiTheme, apiUser] = await Promise.all([
            appApi.fetchTheme().finally(() => (loadingProgress.value += 0.2)),
            fetchUser().finally(() => (loadingProgress.value += 0.2)),
            fetchCompany().finally(() => (loadingProgress.value += 0.2)),
            setResellerAppConfiguration().finally(() => (loadingProgress.value += 0.2)),
        ]);

        if (!apiUser) {
            throw new Error('Utilisateur introuvable');
        }

        if (isNotary.value) {
            i18n.global.locale.value = 'notary';
        }

        if (apiTheme?.id) {
            applicationTheme.value = apiTheme;
            setCssVar('primary', apiTheme.primaryColor);
            setCssVar('secondary', apiTheme.secondaryColor);
        }

        user.value = apiUser;
    }

    return {
        applicationTheme,
        hideSidebarQueryParam,
        hasSidebar,
        sidebar,
        appMode,
        headerTitle,
        extraStyles,
        isDemo,
        isDrawerOpen,
        loadingProgress,
        applicationError,
        appConfiguration,
        isReady,
        reseller,
        company,
        user,
        isCompanyLinkToOpinionSystem,
        isUserLinkToOpinionSystem,
        isLinkedToImmodvisor,
        isBusinessCompany,
        products,
        isNotary,
        isStandalone,
        setDemoMode,
        setTitle,
        addStylesheet,
        fetchApplicationSettings,
        fetchCompany,
        fetchCompanyAuth,
        fetchUser,
    };
});
