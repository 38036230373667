import { defineAsyncComponent } from 'vue';

export enum ThemeNames {
    Curvy = 'curvy',
    Bubble = 'bubble',
    Spiral = 'spiral',
    Vanguard = 'vanguard',
    Panoramic = 'panoramic',
    Notarial = 'notarial',
    Technologic = 'technologic',
    Elegant = 'elegant',
}

export const HeaderPreviewMapping = {
    [ThemeNames.Curvy]: defineAsyncComponent(() => import('@/assets/themes/preview/curvy-header.svg?skipsvgo')),
    [ThemeNames.Bubble]: defineAsyncComponent(() => import('@/assets/themes/preview/bubble-header.svg?skipsvgo')),
    [ThemeNames.Spiral]: defineAsyncComponent(() => import('@/assets/themes/preview/spiral-header.svg?skipsvgo')),
    [ThemeNames.Vanguard]: defineAsyncComponent(() => import('@/assets/themes/preview/vanguard-header.svg?skipsvgo')),
    [ThemeNames.Panoramic]: defineAsyncComponent(() => import('@/assets/themes/preview/panoramic-header.svg?skipsvgo')),
    [ThemeNames.Technologic]: defineAsyncComponent(
        () => import('@/assets/themes/preview/technologic-header.svg?skipsvgo'),
    ),
};

export const FooterPreviewMapping = {
    [ThemeNames.Curvy]: defineAsyncComponent(() => import('@/assets/themes/preview/curvy-footer.svg?skipsvgo')),
    [ThemeNames.Bubble]: defineAsyncComponent(() => import('@/assets/themes/preview/bubble-footer.svg?skipsvgo')),
    [ThemeNames.Spiral]: defineAsyncComponent(() => import('@/assets/themes/preview/spiral-footer.svg?skipsvgo')),
    [ThemeNames.Vanguard]: defineAsyncComponent(() => import('@/assets/themes/preview/vanguard-footer.svg?skipsvgo')),
    [ThemeNames.Panoramic]: defineAsyncComponent(() => import('@/assets/themes/preview/panoramic-footer.svg?skipsvgo')),
    [ThemeNames.Technologic]: defineAsyncComponent(
        () => import('@/assets/themes/preview/technologic-footer.svg?skipsvgo'),
    ),
};
export const ThemeVariants = [
    {
        value: null,
        label: 'Par défaut',
    },
    {
        value: ThemeNames.Curvy,
        label: 'Curvy',
    },
    {
        value: ThemeNames.Bubble,
        label: 'Bubble',
    },
    {
        value: ThemeNames.Spiral,
        label: 'Spiral',
    },
    {
        value: ThemeNames.Vanguard,
        label: 'Vanguard',
    },
    {
        value: ThemeNames.Panoramic,
        label: 'Panoramic',
    },
    {
        value: ThemeNames.Technologic,
        label: 'Technologic',
    },
];
export const ThemeCoverVariants = [
    {
        value: null,
        label: 'Par défaut',
    },
    {
        value: ThemeNames.Curvy,
        label: 'Curvy',
    },
    {
        value: ThemeNames.Vanguard,
        label: 'Vanguard',
    },
    {
        value: ThemeNames.Panoramic,
        label: 'Panoramic',
    },
    {
        value: ThemeNames.Elegant,
        label: 'Elegant',
    },
];
export const ThemeNotaryVariant = { value: ThemeNames.Notarial, label: 'Notaire' };
