import { createHead } from '@unhead/vue';
import type { App } from 'vue';

const head = createHead();

export default {
    install(app: App) {
        app.use(head);
    },
};

export { head };
