import type { RouteRecordRaw } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { checkSidebarQueryParam, checkUserAuthentication } from '@/utils/router.utils';
import { DocumentType } from '@/enums/editor/document/document.enum';
import { RouteNames } from '@/enums/router.enum';

enum GroupNames {
    Scan = 'app-scan',
}

export const routes: RouteRecordRaw[] = [
    {
        path: '/documents/create/rental',
        name: RouteNames.DocumentCreateRental,
        components: {
            default: () => import('@/pages/editor/scan/ScanCreate.vue'),
        },
        meta: {
            product: DocumentType.Avl,
        },
    },
    {
        path: '/documents/create/resale',
        name: RouteNames.DocumentCreateResale,
        components: {
            default: () => import('@/pages/editor/scan/ScanCreate.vue'),
        },
        meta: {
            product: DocumentType.Avm,
        },
    },
    {
        path: '/documents/create/business-resale',
        name: RouteNames.DocumentCreateBusinessResale,
        components: {
            default: () => import('@/pages/editor/scan/ScanCreate.vue'),
        },
        meta: {
            product: DocumentType.AvmBusiness,
        },
    },
    {
        path: '/documents/create/notary-rental',
        name: RouteNames.DocumentCreateNotaryRental,
        components: {
            default: () => import('@/pages/editor/scan/ScanCreate.vue'),
        },
        meta: {
            product: DocumentType.AvlNotary,
        },
    },
    {
        path: '/documents/create/notary-resale',
        name: RouteNames.DocumentCreateNotaryResale,
        components: {
            default: () => import('@/pages/editor/scan/ScanCreate.vue'),
        },
        meta: {
            product: DocumentType.AvmNotary,
        },
    },
    {
        path: '/scans/:scanId(\\d+)/documents/:documentId(\\d+)',
        name: RouteNames.DocumentWithScan,
        components: {
            default: () => import('@/pages/editor/scan/ScanCreate.vue'),
        },
    },
    {
        path: '/documents/:documentId(\\d+)',
        name: RouteNames.DocumentEditRedirection,
        component: () => import('@/pages/editor/document/DocumentRedirect.vue'),
        meta: { disableLayout: true },
    },
    {
        path: '/documents/:documentId(\\d+)/print',
        name: RouteNames.DocumentPrint,
        component: () => import('@/pages/editor/document/DocumentRenderPage.vue'),
        meta: { disableLayout: true },
    },
    {
        path: '/addresses',
        name: RouteNames.AddressesListing,
        components: {
            header: () => import('@/components/layout/headers/ScanHeader.vue'),
            default: () => import('@/pages/editor/scan/ScanListing.vue'),
        },
    },
    {
        path: '/addresses/:tab?/:user(\\d+)?',
        name: RouteNames.AddressesListing,
        components: {
            header: () => import('@/components/layout/headers/ScanHeader.vue'),
            default: () => import('@/pages/editor/scan/ScanListing.vue'),
        },
    },
    {
        path: '/documents',
        name: RouteNames.DocumentListingRedirection,
        redirect: () => ({ name: RouteNames.AddressesListing }),
    },
    {
        path: '/scan',
        name: GroupNames.Scan,
        children: [
            {
                path: ':scanId(\\d+)',
                name: RouteNames.ScanEditor,
                components: {
                    default: () => import('@/pages/editor/scan/ScanEditor.vue'),
                    header: () => import('@/components/layout/headers/ScanEditorHeader.vue'),
                },
            },
            {
                path: ':scanId(\\d+)/:product(avm|avm-notary|avm-business|avl|avl-notary|location|data|address-insight)',
                name: RouteNames.ScanEditorInternal,
                components: {
                    default: () => import('@/pages/editor/scan/ScanEditor.vue'),
                    header: () => import('@/components/layout/headers/ScanEditorHeader.vue'),
                },
            },
        ],
    },
].map(
    (route) =>
        ({
            ...route,
            beforeEnter: multiguard([checkSidebarQueryParam, checkUserAuthentication]),
        }) as RouteRecordRaw,
);

export default routes;
