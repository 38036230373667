import { defineAsyncComponent } from 'vue';
import { useQuasar } from 'quasar';

export const useNoteo = () => {
    const $q = useQuasar();

    function openHelp() {
        $q.dialog({
            component: defineAsyncComponent(() => import('@/components/noteo/HelpDialog.vue')),
        });
    }

    return {
        openHelp,
    };
};
