<script setup lang="ts">
import { useNoteo } from '@/composables/noteo.composable';
import { useGlobalStore } from '@/stores/global.store';
import { ref } from 'vue';
import { QDialog } from 'quasar';

const { openHelp } = useNoteo();
const globalStore = useGlobalStore();

const chatbotDialog = ref<QDialog>();
const chatbotDialogStatus = ref(false);

function openChatbot() {
    if (!chatbotDialogStatus.value) {
        chatbotDialog.value?.show();
    } else {
        chatbotDialog.value?.shake();
    }
}
</script>

<template>
    <q-btn
        v-if="globalStore.isNotary"
        flat
        dense
        icon-right="sym_o_support_agent"
        color="white"
        label="Support"
        size="12px"
        @click="openHelp"
    />
    <template v-else-if="globalStore.reseller.enableChatbot">
        <q-btn
            flat
            dense
            icon-right="sym_o_contact_support"
            color="white"
            label="J'ai besoin d'aide"
            size="12px"
            @click="openChatbot"
        />

        <q-dialog
            ref="chatbotDialog"
            seamless
            position="bottom"
            class="chatbot-dialog"
            :full-width="false"
            :full-height="false"
            @show="chatbotDialogStatus = true"
            @hide="chatbotDialogStatus = false"
        >
            <q-card class="q-ml-auto">
                <q-card-section class="row items-center justify-between">
                    <h2 class="q-my-none">Chatbot</h2>
                    <q-btn icon="sym_o_close" color="secondary" flat dense @click="chatbotDialog.hide" />
                </q-card-section>
                <q-separator />
                <iframe
                    src="https://app.ideta.io/embedded/-O8NepHLnI9WAi6livAs"
                    allow="microphone https://app.ideta.io"
                    class="chatbot-iframe"
                />
            </q-card>
        </q-dialog>
    </template>

    <q-btn
        v-else
        flat
        dense
        icon-right="sym_o_contact_support"
        color="white"
        label="J'ai besoin d'aide"
        href="https://aide.cityscan.fr"
        target="_blank"
        size="12px"
    />
</template>

<style scoped lang="scss">
.chatbot-iframe {
    border: none;
    min-height: 65vh;
    min-width: 450px;
}
</style>
