import { EventBus } from 'quasar';
import type { App } from 'vue';
import type { Event } from '@/models/app.model';
import { EventBusKey } from '@/injection/keys.injection';

const bus = new EventBus<Event>();

export default {
    install(app: App) {
        app.provide(EventBusKey, bus);
    },
};

export { bus };
