import type { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '@/enums/router.enum';
import { useGlobalStore } from '@/stores/global.store';

export const routes: RouteRecordRaw[] = [
    {
        path: '/doc/:doc([\\w-/]+)',
        name: RouteNames.Doc,
        components: {
            default: () => import('@/pages/DocumentationPage.vue'),
        },
        meta: { disableLayout: true },
    },
].map((route) => ({
    ...route,
    beforeEnter: () => {
        const globalStore = useGlobalStore();
        globalStore.isReady = true;
    },
}));

export default routes;
