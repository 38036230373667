<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { QDrawer, setCssVar } from 'quasar';
import { useGlobalStore } from '@/stores/global.store';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { useRouter } from 'vue-router';
import { ApplicationMode, type AppRoute } from '@/models/app.model';
import { DocumentType } from '@/enums/editor/document/document.enum';
import { useNotificationStore } from '@/stores/notification.store';
import { RouteNames } from '@/enums/router.enum';
import { Categories } from '@/enums/solution/solution.enum';
import { useNoteo } from '@/composables/noteo.composable';
import { useRoles } from '@/composables/role.composable';
import { UserRoles } from '@/enums/role.enum';
import { AdminRouteNames } from '@/admin/routes/routes';

const documentMenus: AppRoute[] = [
    {
        icon: 'svguse:/icons/cs-custom-icons.svg#cs-avm',
        label: 'Avis de valeur <br/>marché',
        separator: true,
        to: { name: RouteNames.DocumentCreateResale },
        product: DocumentType.Avm,
    },
    {
        icon: 'svguse:/icons/cs-custom-icons.svg#cs-avl',
        label: 'Avis de valeur <br/>locative',
        separator: true,
        to: { name: RouteNames.DocumentCreateRental },
        product: DocumentType.Avl,
    },
    {
        icon: 'svguse:/icons/cs-custom-icons.svg#cs-avm',
        label: 'Avis de valeur <br/>marché',
        separator: true,
        to: { name: RouteNames.DocumentCreateBusinessResale },
        product: DocumentType.AvmBusiness,
    },
    {
        icon: 'svguse:/icons/cs-custom-icons.svg#cs-avm',
        label: 'Avis de valeur <br/>marché',
        separator: true,
        to: { name: RouteNames.DocumentCreateNotaryResale },
        product: DocumentType.AvmNotary,
    },
    {
        icon: 'svguse:/icons/cs-custom-icons.svg#cs-avl',
        label: 'Avis de valeur <br/>locative',
        separator: true,
        to: { name: RouteNames.DocumentCreateNotaryRental },
        product: DocumentType.AvlNotary,
    },
];

const drawerSize = 120;

const router = useRouter();
const { openHelp } = useNoteo();
const { isGranted } = useRoles();

const globalStore = useGlobalStore();
const authenticationStore = useAuthenticationStore();
const notificationStore = useNotificationStore();

const isStandalone = computed(() => globalStore.appMode === ApplicationMode.Standalone);

const documentExpand = ref(false);
const notificationCenter = ref(false);

const isDisabled = computed(() => !globalStore.company.activeSubscription || globalStore.isDrawerOpen);

const allowedDocumentMenus = computed(() =>
    documentMenus.filter((menu) => globalStore.company.products.includes(menu.product)),
);

const hasDocumentProduct = computed(() => allowedDocumentMenus.value.length > 0);

const countUnreadNotifications = computed(
    () => notificationStore.notifications.filter(({ status }) => status === 'unread').length,
);

const isDocumentsGroupActive = computed(
    () => (router.currentRoute.value.name as string).startsWith('app-document-create') && !documentExpand.value,
);

const hasSolution = computed(() => {
    return globalStore.company.categories.some((service) =>
        [Categories.Api, Categories.Widget].includes(service as any),
    );
});

onMounted(() => {
    notificationStore.fetchNotifications();
    setCssVar('drawer-width', `${drawerSize}px`);
});

function onLogoutClick() {
    authenticationStore.logout().then(() => router.replace({ name: RouteNames.Login }));
}
</script>

<template>
    <q-drawer
        v-model="globalStore.sidebar"
        class="sidebar-drawer"
        :width="drawerSize"
        :breakpoint="768"
        bordered
        :overlay="false"
    >
        <div class="cs-logo-container row wrap justify-center items-center q-px-sm">
            <q-img
                src="/icons/logo.svg"
                class="cs-logo cursor-pointer"
                :class="isStandalone ? 'cursor-pointer' : 'cursor-inherit'"
                spinner-color="white"
                loading="eager"
                fit="contain"
                height="30px"
                @click="isStandalone ? $router.push('/') : null"
            />
        </div>

        <q-list class="menu-tabs">
            <template v-if="hasDocumentProduct">
                <q-expansion-item
                    v-model="documentExpand"
                    hide-expand-icon
                    :header-class="['q-pb-none q-px-none', { active: isDocumentsGroupActive }]"
                    :disable="isDisabled"
                >
                    <template #header>
                        <div class="column items-center no-wrap">
                            <q-item-section avatar class="q-pa-none q-mb-sm">
                                <q-icon name="sym_o_edit_document" class="q-mx-auto" color="secondary" />
                            </q-item-section>

                            <q-item-label
                                class="menu-text relative-position text-center text-uppercase text-weight-medium q-px-md"
                            >
                                <span class="menu-text text-primary text-uppercase text-center text-weight-medium">
                                    Nouvelle estimation
                                </span>
                            </q-item-label>

                            <div>
                                <q-icon
                                    name="sym_o_keyboard_arrow_down"
                                    class="transition-all"
                                    :class="{ 'rotate-180': documentExpand }"
                                />
                            </div>
                        </div>
                    </template>
                    <q-item
                        v-for="(menuItem, index) in allowedDocumentMenus"
                        :key="index"
                        v-ripple
                        class="column items-center q-px-none"
                        clickable
                        :to="menuItem.to"
                        :disable="isDisabled"
                        @click="documentExpand = false"
                    >
                        <q-item-section avatar class="q-pa-none">
                            <q-icon :name="menuItem.icon" class="q-mx-auto" color="secondary" />
                        </q-item-section>

                        <q-item-label class="menu-text text-primary text-uppercase text-center text-weight-medium">
                            <span v-html="menuItem.label"></span>
                        </q-item-label>
                    </q-item>
                </q-expansion-item>
                <q-item
                    v-ripple
                    class="column items-center text-primary q-mt-md"
                    clickable
                    :disable="isDisabled"
                    :to="{ name: RouteNames.AddressesListing }"
                >
                    <q-item-section avatar class="relative-position q-pa-none">
                        <q-icon name="sym_o_folder" class="q-mx-auto" />
                    </q-item-section>

                    <q-item-label class="menu-text relative-position text-center text-uppercase text-weight-medium">
                        <span>Mes estimations</span>
                    </q-item-label>
                </q-item>
            </template>

            <q-item
                v-if="hasSolution && isGranted(UserRoles.ROLE_SHOW_SOLUTIONS)"
                v-ripple
                class="column items-center text-primary"
                clickable
                :disable="isDisabled"
                :to="{ name: RouteNames.SolutionLanding }"
            >
                <q-item-section avatar class="relative-position q-pa-none">
                    <q-icon name="sym_o_package_2" class="q-mx-auto" size="26px" />
                </q-item-section>

                <q-item-label class="menu-text relative-position text-center text-uppercase text-weight-medium">
                    Mes produits
                </q-item-label>
            </q-item>

            <q-item
                v-ripple
                class="column items-center text-primary"
                clickable
                :disable="isDisabled"
                :to="{
                    name: hasDocumentProduct ? RouteNames.SettingsThemesPrintList : RouteNames.SettingsSubscription,
                }"
            >
                <q-item-section avatar class="relative-position q-pa-none">
                    <q-icon name="sym_o_settings" class="q-mx-auto" />
                </q-item-section>

                <q-item-label class="menu-text relative-position text-center text-uppercase text-weight-medium">
                    Paramètres
                </q-item-label>
            </q-item>

            <q-space />

            <template v-if="isGranted(UserRoles.ROLE_ADMIN) && !authenticationStore.impersonate">
                <q-separator />
                <q-item
                    v-ripple
                    class="column items-center text-secondary q-px-none"
                    clickable
                    :to="{ name: AdminRouteNames.Home }"
                >
                    <q-item-section avatar class="relative-position q-pa-none">
                        <q-icon name="sym_o_shield_person" class="q-mx-auto" />
                    </q-item-section>

                    <q-item-label class="menu-text relative-position text-center text-uppercase text-weight-medium">
                        <span>Accès administrateur</span>
                    </q-item-label>
                </q-item>
                <q-separator />
            </template>

            <q-item
                v-ripple
                class="column items-center text-primary q-px-none"
                :class="{ active: notificationCenter }"
                clickable
            >
                <q-item-section avatar class="relative-position q-pa-none">
                    <q-icon name="sym_o_notifications" class="q-mx-auto" />
                    <q-badge v-if="countUnreadNotifications > 0" rounded floating color="secondary">
                        {{ countUnreadNotifications }}
                    </q-badge>
                </q-item-section>

                <q-item-label class="menu-text text-weight-medium text-center text-no-wrap text-uppercase">
                    <span>Notifications</span>
                </q-item-label>

                <q-menu v-model="notificationCenter" anchor="center right" self="center start">
                    <q-list style="min-width: 100px">
                        <q-item class="items-center text-grey-8 q-my-xs" dense>
                            <q-icon name="sym_o_notifications" size="1.3rem" class="q-mr-sm" />
                            <q-item-section>Vos notifications</q-item-section>
                        </q-item>
                        <q-separator />

                        <q-item
                            v-for="(userNotification, index) in notificationStore.notifications"
                            :key="index"
                            :href="userNotification.notification.url"
                            target="_blank"
                            @click="() => notificationStore.markAsRead(userNotification.id)"
                        >
                            <q-item-section v-if="userNotification.status === 'unread'" side>
                                <q-badge rounded color="secondary" />
                            </q-item-section>
                            <q-item-section>
                                <span>{{ userNotification.notification.title }}</span>
                                <span
                                    v-if="userNotification.notification.content?.length > 0"
                                    class="notification-content text-caption text-grey-8"
                                    v-html="userNotification.notification.content"
                                />
                            </q-item-section>
                            <q-item-section v-if="userNotification.notification.url" side>
                                <q-icon name="sym_o_open_in_new" size="1rem" />
                            </q-item-section>
                        </q-item>

                        <q-separator />
                        <q-item
                            class="items-center justify-center text-primary"
                            clickable
                            dense
                            @click="notificationStore.markAllAsRead"
                        >
                            <q-icon name="sym_o_done_all" size="1.3rem" class="q-mr-xs" />
                            Tout marquer comme lu
                        </q-item>
                    </q-list>
                </q-menu>
            </q-item>

            <q-item
                v-if="globalStore.isNotary"
                v-ripple
                class="column items-center text-primary q-px-none"
                clickable
                @click="openHelp"
            >
                <q-item-section avatar class="relative-position q-pa-none">
                    <q-icon name="sym_o_support_agent" class="q-mx-auto" />
                </q-item-section>

                <q-item-label class="menu-text relative-position text-center text-uppercase text-weight-medium">
                    <span>Support</span>
                </q-item-label>
            </q-item>

            <q-item
                v-if="hasDocumentProduct"
                v-ripple
                class="column items-center text-primary q-px-none"
                clickable
                href="https://aide.cityscan.fr"
                target="_blank"
            >
                <q-item-section avatar class="relative-position q-pa-none">
                    <q-icon name="sym_o_contact_support" class="q-mx-auto" />
                </q-item-section>

                <q-item-label class="menu-text relative-position text-center text-uppercase text-weight-medium q-px-md">
                    <span>Aide en ligne</span>
                    <q-badge floating color="transparent" text-color="primary">
                        <q-icon name="sym_o_open_in_new" />
                    </q-badge>
                </q-item-label>
            </q-item>

            <template v-if="isStandalone">
                <q-item
                    v-ripple
                    class="relative-position column items-center text-secondary q-px-none text-uppercase"
                    clickable
                    :disable="globalStore.isDrawerOpen"
                    @click="onLogoutClick"
                >
                    <q-item-section avatar class="q-pa-none">
                        <q-icon name="sym_o_logout" class="q-mx-auto" />
                    </q-item-section>

                    <q-item-label class="menu-text text-weight-medium text-center text-no-wrap">
                        Se déconnecter
                    </q-item-label>
                </q-item>
            </template>
        </q-list>
    </q-drawer>
</template>

<style lang="scss">
@use '@/css/quasar.variables' as v;

.cs-logo-container {
    min-height: var(--q-header-height);

    .cs-logo {
        cursor: pointer;
    }
}

.sidebar-drawer {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .menu-tabs {
        display: flex !important;
        flex-direction: column;
        flex-grow: 1;
        height: auto;

        .menu-separator {
            width: 100%;
        }

        .q-item {
            gap: 8px;

            .menu-text {
                font-size: 12px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 2px;
                background-color: transparent;
            }

            &.q-router-link--active,
            &.active {
                &:after {
                    background-color: v.$secondary;
                }
            }
        }
    }
}

.notification-content p {
    margin-bottom: 0;
}
</style>
