import { api } from '@/plugins/axios';
import type { AuthKeyCredentialsPayload, LoginApiResponse } from '@/models/authentication.model';
import type {
    EditPasswordPayload,
    EmailValidationPayload,
    EmailValidationResponse,
    ForgottenPasswordPayload,
    RegisterPayload,
} from '@/models/user.model';

export const loginWithAuthenticationCode = async (authorization_code: string) =>
    (await api.post<LoginApiResponse>('/app/security/embedded', { authorization_code })).data;

export const loginWithCredentials = async (username: string, password: string, recaptchaResponse: string) =>
    (await api.post<LoginApiResponse>('/security/login', { username, password, recaptchaResponse })).data;

export const refreshAccessToken = async (refreshToken: string) =>
    (
        await api.post<LoginApiResponse>(
            '/app/security/refresh_token',
            { refresh_token: refreshToken },
            { disableNotification: true, removeAuthorization: true },
        )
    )?.data;

export const registerUser = async (payload: RegisterPayload) =>
    await api.post('/app/security/register', payload, { disableNotification: true });

export const resetPassword = async (payload: ForgottenPasswordPayload) =>
    await api.post('/app/security/reset-password', payload, { disableNotification: true });

export const editPassword = async (payload: EditPasswordPayload) =>
    await api.post('/app/security/edit-password', payload);

export const validateEmail = async (payload: EmailValidationPayload, signal?: AbortSignal) =>
    (await api.post<EmailValidationResponse>('/app/security/check-email', payload, { signal })).data;

export const getCredentials = async (payload: AuthKeyCredentialsPayload) =>
    (await api.post<any>('/security/credentials', payload)).data;
