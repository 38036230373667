import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Dialog, LocalStorage, Notify, Quasar } from 'quasar';
import quasarLang from 'quasar/lang/fr';
import moment from 'moment';
import { registerLocale } from 'i18n-iso-countries';
import 'moment/dist/locale/fr';
import i18nCountriesFr from 'i18n-iso-countries/langs/fr.json';
import VueMaplibreGl from '@indoorequal/vue-maplibre-gl';
import quasarIconSet from 'quasar/icon-set/material-icons-outlined';

import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';

import 'quasar/src/css/index.sass';
import 'quasar/src/css/flex-addon.sass';
import 'maplibre-gl/dist/maplibre-gl.css';
import 'quill/dist/quill.core.css';
import './css/app.scss';

import registerPlugins from '@/plugins';
import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(Quasar, {
    plugins: { Dialog, LocalStorage, Notify },
    lang: quasarLang,
    iconSet: quasarIconSet,
});

app.use(createPinia());
app.use(router);
app.use(VueMaplibreGl);

registerPlugins(app);

registerLocale(i18nCountriesFr);

moment.locale('fr');

app.mount('#app');
