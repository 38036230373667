import { createI18n } from 'vue-i18n';
import frTranslations from '@/translations/fr-FR.json';
import notaryTranslations from '@/translations/notary-FR.json';
import type { App } from 'vue';

const i18n = createI18n({
    legacy: false,
    locale: 'fr',
    fallbackLocale: {
        notary: ['fr'],
    },
    messages: {
        fr: frTranslations,
        notary: notaryTranslations,
    },
});

export default {
    install(app: App) {
        app.use(i18n);
    },
};

export { i18n };
