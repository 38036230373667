import { api } from '@/plugins/axios';
import type { UserNotification } from '@/models/notification.model';
import { HttpStatusCode } from 'axios';

export const fetchNotifications = async () => (await api.get<UserNotification[]>('/app/notifications')).data;

export const markAsRead = async (id: number) => {
    const apiResponse = await api.post(`/app/notifications/${id}`);

    return apiResponse.status === HttpStatusCode.NoContent;
};

export const markAllAsRead = async () => {
    const apiResponse = await api.post('/app/notifications/read-all');

    return apiResponse.status === HttpStatusCode.NoContent;
};
