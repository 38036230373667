export enum UserRoles {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_CREATE_AGENT = 'ROLE_CREATE_AGENT',
    ROLE_REMOVE_AGENT = 'ROLE_REMOVE_AGENT',
    ROLE_EDIT_AGENT = 'ROLE_EDIT_AGENT',
    ROLE_CREATE_AGENCY = 'ROLE_CREATE_AGENCY',
    ROLE_REMOVE_AGENCY = 'ROLE_REMOVE_AGENCY',
    ROLE_EDIT_AGENCY = 'ROLE_EDIT_AGENCY',
    ROLE_CREATE_DOCUMENT_THEME = 'ROLE_CREATE_DOCUMENT_THEME',
    ROLE_REMOVE_DOCUMENT_THEME = 'ROLE_REMOVE_DOCUMENT_THEME',
    ROLE_EDIT_DOCUMENT_THEME = 'ROLE_EDIT_DOCUMENT_THEME',
    ROLE_CREATE_DOCUMENT_TEMPLATE = 'ROLE_CREATE_DOCUMENT_TEMPLATE',
    ROLE_REMOVE_DOCUMENT_TEMPLATE = 'ROLE_REMOVE_DOCUMENT_TEMPLATE',
    ROLE_CREATE_CUSTOM_PROPERTY = 'ROLE_CREATE_CUSTOM_PROPERTY',
    ROLE_EDIT_CUSTOM_PROPERTY = 'ROLE_EDIT_CUSTOM_PROPERTY',
    ROLE_REMOVE_CUSTOM_PROPERTY = 'ROLE_REMOVE_CUSTOM_PROPERTY',
    ROLE_OPINION_SYSTEM_LINK_COMPANY = 'ROLE_OPINION_SYSTEM_LINK_COMPANY',
    ROLE_OPINION_SYSTEM_UNLINK_COMPANY = 'ROLE_OPINION_SYSTEM_UNLINK_COMPANY',
    ROLE_OPINION_SYSTEM_LINK_COLLABORATOR = 'ROLE_OPINION_SYSTEM_LINK_COLLABORATOR',
    ROLE_OPINION_SYSTEM_UNLINK_COLLABORATOR = 'ROLE_OPINION_SYSTEM_UNLINK_COLLABORATOR',
    ROLE_IMMODVISOR_LINK_COMPANY = 'ROLE_IMMODVISOR_LINK_COMPANY',
    ROLE_IMMODVISOR_UNLINK_COMPANY = 'ROLE_IMMODVISOR_UNLINK_COMPANY',
    ROLE_SUBSCRIPTION_SHOW_STRIPE = 'ROLE_SUBSCRIPTION_SHOW_STRIPE',
    ROLE_SUBSCRIPTION_CANCEL_SUBSCRIPTION = 'ROLE_SUBSCRIPTION_CANCEL_SUBSCRIPTION',
    ROLE_USERS_CREATE = 'ROLE_USERS_CREATE',
    ROLE_USERS_EDIT = 'ROLE_USERS_EDIT',
    ROLE_USERS_DELETE = 'ROLE_USERS_DELETE',
    ROLE_USERS_CHANGE_ROLES = 'ROLE_USERS_CHANGE_ROLES',

    // Solution
    ROLE_SHOW_SOLUTIONS = 'ROLE_SHOW_SOLUTIONS',
    ROLE_EDIT_SOLUTIONS = 'ROLE_EDIT_SOLUTIONS',

    // Document
    ROLE_ALLOW_EMPTY_DOCUMENT = 'ROLE_ALLOW_EMPTY_DOCUMENT',
    ROLE_SHOW_COLLABORATOR_SCANS = 'ROLE_SHOW_COLLABORATOR_SCANS',
    ROLE_EDIT_COLLABORATOR_DOCUMENTS = 'ROLE_EDIT_COLLABORATOR_DOCUMENTS',
    ROLE_REMOVE_COLLABORATOR_DOCUMENTS = 'ROLE_REMOVE_COLLABORATOR_DOCUMENTS',

    // Brand roles
    ROLE_CREATE_BRAND_DOCUMENT_THEME = 'ROLE_CREATE_BRAND_DOCUMENT_THEME',
    ROLE_REMOVE_BRAND_DOCUMENT_THEME = 'ROLE_REMOVE_BRAND_DOCUMENT_THEME',
    ROLE_EDIT_BRAND_DOCUMENT_THEME = 'ROLE_EDIT_BRAND_DOCUMENT_THEME',
    ROLE_CREATE_BRAND_DOCUMENT_TEMPLATE = 'ROLE_CREATE_BRAND_DOCUMENT_TEMPLATE',
    ROLE_REMOVE_BRAND_DOCUMENT_TEMPLATE = 'ROLE_REMOVE_BRAND_DOCUMENT_TEMPLATE',

    // COMPANY
    ROLE_CREATE_COMPANY_DOCUMENT_TEMPLATE = 'ROLE_CREATE_COMPANY_DOCUMENT_TEMPLATE',
    ROLE_REMOVE_COMPANY_DOCUMENT_TEMPLATE = 'ROLE_REMOVE_COMPANY_DOCUMENT_TEMPLATE',
}

export const RoleDetails = [
    {
        id: 'agents',
        name: 'Agent',
        roles: {
            [UserRoles.ROLE_CREATE_AGENT]: 'Créer',
            [UserRoles.ROLE_REMOVE_AGENT]: 'Supprimer',
            [UserRoles.ROLE_EDIT_AGENT]: 'Modifier',
        },
    },
    {
        id: 'agencies',
        name: 'Agence',
        roles: {
            [UserRoles.ROLE_CREATE_AGENCY]: 'Créer',
            [UserRoles.ROLE_REMOVE_AGENCY]: 'Supprimer',
            [UserRoles.ROLE_EDIT_AGENCY]: 'Modifier',
        },
    },
    {
        id: 'themes',
        name: 'Thèmes de document',
        roles: {
            [UserRoles.ROLE_CREATE_DOCUMENT_THEME]: 'Créer',
            [UserRoles.ROLE_REMOVE_DOCUMENT_THEME]: 'Supprimer',
            [UserRoles.ROLE_EDIT_DOCUMENT_THEME]: 'Modifier',
        },
    },
    {
        id: 'document',
        name: 'Documents',
        roles: {
            [UserRoles.ROLE_SHOW_COLLABORATOR_SCANS]: 'Afficher les documents des collaborateurs',
            [UserRoles.ROLE_EDIT_COLLABORATOR_DOCUMENTS]: 'Modifier les documents des collaborateurs',
            [UserRoles.ROLE_REMOVE_COLLABORATOR_DOCUMENTS]: 'Supprimer les documents des collaborateurs',
        },
    },
    {
        id: 'templates',
        name: 'Modèles de document',
        roles: {
            [UserRoles.ROLE_CREATE_DOCUMENT_TEMPLATE]: 'Créer',
            [UserRoles.ROLE_REMOVE_DOCUMENT_TEMPLATE]: 'Supprimer',
            [UserRoles.ROLE_CREATE_COMPANY_DOCUMENT_TEMPLATE]: 'Créer pour la société',
            [UserRoles.ROLE_REMOVE_COMPANY_DOCUMENT_TEMPLATE]: 'Supprimer de la société',
        },
    },
    {
        id: 'properties',
        name: 'Mes biens',
        roles: {
            [UserRoles.ROLE_CREATE_CUSTOM_PROPERTY]: 'Créer',
            [UserRoles.ROLE_REMOVE_CUSTOM_PROPERTY]: 'Supprimer',
        },
    },
    {
        id: 'customer-reviews',
        name: 'Avis clients',
        roles: {
            [UserRoles.ROLE_OPINION_SYSTEM_LINK_COMPANY]: '[Opinion System] Lier une société',
            [UserRoles.ROLE_OPINION_SYSTEM_UNLINK_COMPANY]: '[Opinion System] Supprimer la société liée',
            [UserRoles.ROLE_OPINION_SYSTEM_LINK_COLLABORATOR]: '[Opinion System] Lier un collaborateur',
            [UserRoles.ROLE_OPINION_SYSTEM_UNLINK_COLLABORATOR]: '[Opinion System] Supprimer le lien du collaborateur',
            [UserRoles.ROLE_IMMODVISOR_LINK_COMPANY]: '[Immodvisor] Lier une société',
            [UserRoles.ROLE_IMMODVISOR_UNLINK_COMPANY]: "[Immodvisor] Supprimer le lien d'une société",
        },
    },
    {
        id: 'subscription',
        name: 'Abonnement',
        roles: {
            [UserRoles.ROLE_SUBSCRIPTION_SHOW_STRIPE]: 'Afficher Stripe Portal',
            [UserRoles.ROLE_SUBSCRIPTION_CANCEL_SUBSCRIPTION]: 'Annuler un abonnement',
        },
    },
    {
        id: 'solutions',
        name: 'Solutions',
        roles: {
            [UserRoles.ROLE_SHOW_SOLUTIONS]: 'Afficher les produits',
            [UserRoles.ROLE_EDIT_SOLUTIONS]: 'Éditer les produits',
        },
    },
    {
        id: 'users',
        name: 'Utilisateurs',
        roles: {
            [UserRoles.ROLE_USERS_CREATE]: 'Créer',
            [UserRoles.ROLE_USERS_DELETE]: 'Supprimer',
            [UserRoles.ROLE_USERS_EDIT]: 'Modifier',
            [UserRoles.ROLE_USERS_CHANGE_ROLES]: 'Gérer les rôles',
        },
    },
];

export const BrandRoleDetails = [
    {
        id: 'brand',
        name: 'Réseau',
        roles: {
            [UserRoles.ROLE_REMOVE_BRAND_DOCUMENT_THEME]: 'Supprimer un thème de document',
            [UserRoles.ROLE_EDIT_BRAND_DOCUMENT_THEME]: 'Modifier un thème de document',
            [UserRoles.ROLE_CREATE_BRAND_DOCUMENT_TEMPLATE]: 'Créer un modèle de document',
            [UserRoles.ROLE_REMOVE_BRAND_DOCUMENT_TEMPLATE]: 'Supprimer un modèle de document',
            [UserRoles.ROLE_ALLOW_EMPTY_DOCUMENT]: 'Possibilité de créer un document sans modèle',
        },
    },
];

export const DefaultRoles = [
    UserRoles.ROLE_CREATE_AGENT,
    UserRoles.ROLE_REMOVE_AGENT,
    UserRoles.ROLE_EDIT_AGENT,
    UserRoles.ROLE_CREATE_AGENCY,
    UserRoles.ROLE_REMOVE_AGENCY,
    UserRoles.ROLE_EDIT_AGENCY,
    UserRoles.ROLE_CREATE_DOCUMENT_THEME,
    UserRoles.ROLE_REMOVE_DOCUMENT_THEME,
    UserRoles.ROLE_EDIT_DOCUMENT_THEME,
    UserRoles.ROLE_CREATE_DOCUMENT_TEMPLATE,
    UserRoles.ROLE_REMOVE_DOCUMENT_TEMPLATE,
    UserRoles.ROLE_CREATE_CUSTOM_PROPERTY,
    UserRoles.ROLE_EDIT_CUSTOM_PROPERTY,
    UserRoles.ROLE_REMOVE_CUSTOM_PROPERTY,
    UserRoles.ROLE_OPINION_SYSTEM_LINK_COMPANY,
    UserRoles.ROLE_OPINION_SYSTEM_UNLINK_COMPANY,
    UserRoles.ROLE_OPINION_SYSTEM_LINK_COLLABORATOR,
    UserRoles.ROLE_OPINION_SYSTEM_UNLINK_COLLABORATOR,
    UserRoles.ROLE_IMMODVISOR_LINK_COMPANY,
    UserRoles.ROLE_IMMODVISOR_UNLINK_COMPANY,
    UserRoles.ROLE_SUBSCRIPTION_SHOW_STRIPE,
    UserRoles.ROLE_CREATE_COMPANY_DOCUMENT_TEMPLATE,
    UserRoles.ROLE_REMOVE_COMPANY_DOCUMENT_TEMPLATE,
    UserRoles.ROLE_ALLOW_EMPTY_DOCUMENT,
    UserRoles.ROLE_SHOW_SOLUTIONS,
    UserRoles.ROLE_EDIT_SOLUTIONS,
];
