import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import * as authenticationApi from '@/services/api/authentication.api';
import * as appApi from '@/services/api/app.api';
import type { AxiosError } from 'axios';
import { api } from '@/plugins/axios';
import { jwtDecode } from 'jwt-decode';

import { addAuthorizationHeader, removeAuthorizationHeader } from '@/utils/axios.utils';
import { useGlobalStore } from '@/stores/global.store';
import { useStorageStore } from '@/stores/storage.store';
import { SessionStorage } from 'quasar';
import { StorageKeys } from '@/models/storage.model';
import type { CityscanJwtPayload } from '@/admin/models/jwt.model';
import type { AuthKeyCredentialsPayload } from '@/models/authentication.model';

export const useAuthenticationStore = defineStore('authentication', () => {
    const storage = useStorageStore();

    const accessToken = ref<string>();
    const refreshToken = ref<string>();
    const isAuthenticated = ref(false);
    // Used by admin to impersonate user
    const impersonate = ref(SessionStorage.getItem<number>(StorageKeys.Impersonate));

    // Computed values
    const hasAccessToken = computed(() => !!accessToken.value);

    const decodedToken = computed(() =>
        hasAccessToken.value ? jwtDecode<CityscanJwtPayload>(accessToken.value) : null,
    );

    const roles = computed(() => decodedToken.value?.roles || []);

    function loadFromStorage() {
        accessToken.value = storage.get<string>(StorageKeys.AccessToken);
        refreshToken.value = storage.get<string>(StorageKeys.RefreshToken);

        if (accessToken.value && refreshToken.value) {
            addAuthorizationHeader(api, accessToken.value);
            isAuthenticated.value = true;
        }
    }

    function setAccessToken(token: string) {
        if (!token) {
            storage.remove(StorageKeys.AccessToken);
        } else {
            storage.set(StorageKeys.AccessToken, token);
        }
        accessToken.value = token;
    }

    function setRefreshToken(token: string) {
        if (!token) {
            storage.remove(StorageKeys.RefreshToken);
        } else {
            storage.set(StorageKeys.RefreshToken, token);
        }
        refreshToken.value = token;
    }

    function setImpersonate(id?: number) {
        if (!id) {
            SessionStorage.remove(StorageKeys.Impersonate);
        } else {
            SessionStorage.set(StorageKeys.Impersonate, id);
        }
        impersonate.value = id;
    }

    async function handleAccessTokenRefresh(refreshToken: string) {
        const tokenRefreshResponse = await authenticationApi.refreshAccessToken(refreshToken);
        setAccessToken(tokenRefreshResponse?.token);
        setRefreshToken(tokenRefreshResponse?.refresh_token);
        return tokenRefreshResponse;
    }

    function $reset() {
        setAccessToken(null);
        setRefreshToken(null);
        removeAuthorizationHeader(api);
        isAuthenticated.value = false;
    }

    async function embeddedAuthenticate(authorizationCode: string) {
        const globalStore = useGlobalStore();

        try {
            const loginResponse = await authenticationApi.loginWithAuthenticationCode(authorizationCode);
            setAccessToken(loginResponse.token);
            setRefreshToken(loginResponse.refresh_token);
            globalStore.loadingProgress += 0.2;
        } catch (e: any) {
            if ((e as AxiosError)?.response?.data?.['error'] === 'invalid_request') {
                console.error('Authentication error : Rejected authentication code!');
            }
            throw (e as AxiosError)?.response?.data?.['hint'] || "Une erreur s'est produite";
        }

        addAuthorizationHeader(api, accessToken.value);

        return (isAuthenticated.value = true);
    }

    async function handleUserLogin(username: string, password: string, recaptchaResponse: string) {
        try {
            const loginResponse = await authenticationApi.loginWithCredentials(username, password, recaptchaResponse);

            setAccessToken(loginResponse.token);
            setRefreshToken(loginResponse.refresh_token);

            addAuthorizationHeader(api, accessToken.value);

            isAuthenticated.value = true;

            return loginResponse;
        } catch (e: any) {
            if ((e as AxiosError)?.response?.data?.['error'] === 'invalid_request') {
                console.error('User not authorized!');
            }
            throw e;
        }
    }

    async function logout() {
        const isSuccess = await appApi.logout();
        setAccessToken(null);
        setRefreshToken(null);
        isAuthenticated.value = false;
        removeAuthorizationHeader(api);

        return isSuccess;
    }

    async function getCredentials(payload: AuthKeyCredentialsPayload) {
        return authenticationApi.getCredentials(payload);
    }

    return {
        loadFromStorage,
        $reset,
        accessToken,
        refreshToken,
        isAuthenticated,
        hasAccessToken,
        roles,
        handleAccessTokenRefresh,
        handleUserLogin,
        embeddedAuthenticate,
        impersonate,
        setImpersonate,
        logout,
        getCredentials,
    };
});
