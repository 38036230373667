<script setup lang="ts">
import AppLayout from '@/components/layout/AppLayout.vue';
import LoadingOverlay from '@/components/shared/LoadingOverlay.vue';
import { useGlobalStore } from '@/stores/global.store';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { RouteNames } from '@/enums/router.enum';

const router = useRouter();
const globalStore = useGlobalStore();
const authenticationStore = useAuthenticationStore();
const isRouterReady = ref(false);

watch(
    router.currentRoute,
    (value) => {
        document.body.setAttribute('route', value.name as string);
    },
    { immediate: true },
);

router.isReady().then(async () => {
    globalStore.loadingProgress += 0.2;

    const impersonate = router.currentRoute.value.query?.impersonate;
    if (authenticationStore.isAuthenticated && impersonate) {
        console.log('[DEBUG|ROUTER] Impersonate detected');
        authenticationStore.setImpersonate(Number.parseInt(impersonate as string));

        await router.replace({ name: RouteNames.Home, query: {} });
        window.location.reload();
    }

    isRouterReady.value = true;
});
</script>

<template>
    <LoadingOverlay :loading="!isRouterReady" :loading-progress="globalStore.loadingProgress">
        <AppLayout />
    </LoadingOverlay>
</template>
