<script setup lang="ts">
import { useRoute } from 'vue-router';
import { colors, getCssVar, QHeader, setCssVar, useQuasar } from 'quasar';
import { computed, onMounted, ref, watch } from 'vue';
import { debouncedWatch, useElementSize } from '@vueuse/core';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global.store';
import AppSidebar from '@/components/layout/AppSidebar.vue';
import ImpersonateBanner from '@/components/shared/security/ImpersonateBanner.vue';
import LoadingOverlay from '@/components/shared/LoadingOverlay.vue';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { useRoles } from '@/composables/role.composable';
import { UserRoles } from '@/enums/role.enum';
import changeAlpha = colors.changeAlpha;
import HelpButton from '@/components/shared/header/HelpButton.vue';

const $q = useQuasar();
const { instance } = useReCaptcha();
const route = useRoute();
const { isGranted } = useRoles();
const globalStore = useGlobalStore();
const authenticationStore = useAuthenticationStore();
const { headerTitle, applicationError, loadingProgress, isReady } = storeToRefs(globalStore);

const appHeader = ref<QHeader>(null);
const headerSize = useElementSize(appHeader);

const isAdmin = computed(() => (route.name as string).includes('admin'));

const showSidebar = computed(() => globalStore.hasSidebar && !globalStore.hideSidebarQueryParam);

const showBurgerMenu = computed(() => {
    if ($q.screen.lt.md) {
        if (authenticationStore.impersonate || isGranted(UserRoles.ROLE_ADMIN)) {
            return true;
        }

        return showSidebar.value;
    }

    return false;
});

onMounted(() => {
    setCssVar('primary-rgba', changeAlpha(getCssVar('primary'), 0.3));
});

watch(
    () => headerSize,
    () => {
        setCssVar('header-height', `${headerSize.height.value}px`);
    },
    { immediate: true, deep: true },
);

watch(
    () => route.meta.showRecaptcha,
    (value) => {
        if (value) {
            instance.value?.showBadge();
        } else {
            instance.value?.hideBadge();
        }
    },
    { immediate: true },
);

debouncedWatch(
    () => $q.screen.width,
    () => {
        if (!globalStore.appConfiguration?.hideLeftSidebar) {
            globalStore.sidebar = $q.screen.gt.sm;
        }
    },
    { immediate: true, debounce: 250 },
);
</script>

<template>
    <LoadingOverlay :loading="!isReady" :loading-progress="loadingProgress">
        <q-banner v-if="applicationError" dense inline-actions class="text-white bg-red fixed-center">
            <template #avatar>
                <q-icon name="error" color="white" />
            </template>
            {{ applicationError }}
        </q-banner>

        <template v-else-if="isReady">
            <ImpersonateBanner />
            <q-layout
                v-if="!$route.meta.disableLayout"
                view="lHh LpR lFf"
                container
                class="flex no-wrap bg-grey-3"
                :class="[route.name, { 'admin-layout': isAdmin }]"
            >
                <q-header ref="appHeader" class="app-header column no-wrap-xl" :class="{ 'no-menu': !showSidebar }">
                    <div class="bg-inverted-gradient z-under absolute-full" />
                    <div class="header-image-background z-under absolute-full" />
                    <q-btn
                        v-if="showBurgerMenu"
                        icon="sym_o_menu"
                        class="absolute-top-left menu-btn z-fab"
                        @click="globalStore.sidebar = !globalStore.sidebar"
                    />
                    <h2
                        v-if="!globalStore.appConfiguration?.hideBreadcrumbs && headerTitle.length !== 0"
                        class="header-title ellipsis q-ml-lg q-my-sm q-my-xl-md"
                        :class="{ 'header-title-md': $q.screen.lt.md }"
                    >
                        {{ headerTitle }}
                    </h2>
                    <router-view v-slot="{ Component }" name="header">
                        <component :is="Component" v-if="!!Component" />
                        <aside v-else class="absolute-right row items-center q-pr-lg">
                            <HelpButton />
                        </aside>
                    </router-view>
                </q-header>
                <router-view v-if="showSidebar" v-slot="{ Component }" name="sidebar">
                    <component :is="Component" v-if="!!Component" />
                    <AppSidebar v-else />
                </router-view>
                <q-page-container>
                    <router-view />
                </q-page-container>
            </q-layout>
            <template v-else>
                <router-view />
            </template>
        </template>
    </LoadingOverlay>
</template>

<style scoped lang="scss">
@use 'sass:map';
@use 'quasar/src/css/variables' as v;

.header-title-md {
    margin: map.get(v.$space-md, 'x') 0 auto 80px;
}

.menu-btn {
    margin: 12px 0 0 12px;
}

.app-header {
    min-height: $appHeaderHeight;
}

.header-image-background {
    opacity: 0.04;
    background: url('/assets/images/app-header-background.png') center center;
}

.header-title {
    font-weight: 600;
}
</style>

<style lang="scss">
@use 'quasar/src/css/variables' as v;

#ideta_ii {
    width: 54px !important;
    height: 54px !important;
}

@media screen and (min-width: v.$breakpoint-xl-min) {
    .app-documents-edit,
    .app-documents-edit-data {
        header .header-title {
            width: 370px;
        }
    }
}

@media screen and (max-width: v.$breakpoint-lg-max) {
    .app-documents-edit,
    .app-documents-edit-data {
        .app-header {
            padding-left: 60px;

            &.no-menu {
                padding-left: 0;
            }
        }
    }
}

.admin-layout {
    // AppLayout header size overriden to be shorter
    $appHeaderHeight: 40px;

    .app-header {
        min-height: $appHeaderHeight !important;
    }
}
</style>
