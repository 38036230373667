import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { type Ref, watch, type WatchStopHandle } from 'vue';
import { buildAuthorizationHeaderValue } from '@/utils/authentication.utils';

export const addAuthorizationHeader = (instance: AxiosInstance, accessToken: string) => {
    instance.defaults.headers.common['Authorization'] = buildAuthorizationHeaderValue(accessToken);
};

export const removeAuthorizationHeader = (instance: AxiosInstance) => {
    delete instance.defaults.headers.common['Authorization'];
};

// Use to handle multiple requests when refresh token request is send to the server
// It will wait until the refresh token finished and rerun previous failed requests
export const handleMultipleRefreshTokenRequests = async (
    instance: AxiosInstance,
    isTokenRefreshingRef: Ref<boolean>,
    axiosError: AxiosError,
    accessToken: Ref<string>,
    abortController: AbortController,
) => {
    let watchStopHandle: WatchStopHandle | null = null;
    return new Promise<AxiosResponse | void>((resolve, reject) => {
        watchStopHandle = watch(
            isTokenRefreshingRef,
            (isRefreshing) => {
                if (!isRefreshing && axiosError.config) {
                    axiosError.config.headers.set('Authorization', buildAuthorizationHeaderValue(accessToken.value));
                    axiosError.config.signal = abortController.signal;
                    resolve(instance.request(axiosError.config).catch(reject));
                }
            },
            { immediate: true },
        );
    }).finally(() => watchStopHandle?.());
};
