export enum RouteNames {
    NotFound = 'app-not-found',
    Login = 'app-login',
    Register = 'app-register',
    ForgottenPassword = 'forgotten-password',
    Home = 'app-home',
    Subscribe = 'app-subscribe',
    Doc = 'app-doc',
    EditPassword = 'app-edit-password',
    ShowCredentials = 'app-show-credentials',
    // Document creation
    DocumentCreateRental = 'app-document-create-rental',
    DocumentCreateResale = 'app-document-create-resale',
    DocumentCreateBusinessResale = 'app-document-create-business-resale',
    DocumentCreateNotaryRental = 'app-document-create-notary-rental',
    DocumentCreateNotaryResale = 'app-document-create-notary-resale',
    DocumentWithScan = 'app-document-create-scan',
    // Document print
    DocumentListingRedirection = 'app-documents-listing-redirection',
    DocumentEditRedirection = 'app-documents-edit-redirection',
    DocumentPrint = 'app-documents-print',
    // Scan listing
    AddressesListing = 'app-addresses-list',
    // Document editor
    ScanEditor = 'app-scan-editor',
    ScanEditorInternal = 'app-scan-editor-internal',
    // Settings
    Settings = 'app-settings',
    SettingsThemesPrintList = 'app-settings-themes',
    SettingsThemesPrintCreate = 'app-settings-themes-create',
    SettingsThemesPrintEdit = 'app-settings-themes-edit',
    SettingsAgenciesList = 'app-settings-agencies',
    SettingsAgenciesCreate = 'app-settings-agencies-create',
    SettingsAgenciesEdit = 'app-settings-agencies-edit',
    SettingsAgentsList = 'app-settings-agents',
    SettingsAgentsCreate = 'app-settings-agents-create',
    SettingsAgentsEdit = 'app-settings-agents-edit',
    SettingsPropertiesList = 'app-settings-properties',
    SettingsPropertiesCreate = 'app-settings-properties-create',
    SettingsReviewsLanding = 'app-settings-reviews-landing',
    SettingsTemplatesList = 'app-settings-templates-list',
    SettingsSubscription = 'app-settings-subscription',
    SettingsUsersList = 'app-settings-users-list',
    SettingsUsersCreate = 'app-settings-users-create',
    SettingsUsersEdit = 'app-settings-users-edit',

    // Products
    Solutions = 'app-solutions',
    SolutionLanding = 'app-solutions-landing',
    SolutionUserGuide = 'app-solutions-user-guide',
    SolutionDetails = 'app-solutions-details',
    SolutionDetailsInner = 'app-solutions-details-inner',
}
