import { defineStore } from 'pinia';
import { ref } from 'vue';

import * as notificationApi from '@/services/api/editor/notification.api';
import type { UserNotification } from '@/models/notification.model';

export const useNotificationStore = defineStore('notification', () => {
    const notifications = ref<UserNotification[]>([]);

    async function fetchNotifications() {
        notifications.value = await notificationApi.fetchNotifications();
    }

    async function markAsRead(id: number) {
        await notificationApi.markAsRead(id);

        const notificationIndex = notifications.value.findIndex(({ id: _id }) => id === _id);

        if (notificationIndex !== -1) {
            notifications.value[notificationIndex].status = 'read';
        }
    }

    async function markAllAsRead() {
        await notificationApi.markAllAsRead();
        notifications.value = notifications.value.map((notification) => ({ ...notification, status: 'read' }));
    }

    return {
        notifications,
        fetchNotifications,
        markAsRead,
        markAllAsRead,
    };
});
