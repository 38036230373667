import type { RouteRecordRaw } from 'vue-router';
import { checkUserAuthentication } from '@/utils/router.utils';

import { RouteNames } from '@/enums/router.enum';

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: RouteNames.Home,
        components: {
            header: () => import('@/components/layout/headers/DashboardHeader.vue'),
            default: () => import('@/pages/HomePage.vue'),
        },
    },
    {
        path: '/subscribe',
        name: RouteNames.Subscribe,
        components: {
            default: () => import('@/pages/security/SubscribePage.vue'),
        },
        meta: { disableLayout: true },
    },
].map((route) => ({ ...route, beforeEnter: checkUserAuthentication }));

export default routes;
