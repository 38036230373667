import { type AxiosResponse } from 'axios';
import { Notify } from 'quasar';
import { api } from '@/plugins/axios';
import router from '@/router';
import { RouteNames } from '@/enums/router.enum';

export default {
    install() {
        api.interceptors.response.use(
            (res: AxiosResponse) => {
                return res;
            },
            async (error: any) => {
                if (router.currentRoute.value.name !== RouteNames.DocumentPrint) {
                    if (error.code === 'ERR_NETWORK') {
                        Notify.create({
                            type: 'negative',
                            message: 'Erreur internet. Veuillez vérifier votre connexion internet et réessayer.',
                        });
                    }
                    if (!error?.config?.disableNotification && error.response) {
                        let message: string;
                        switch (error.response.status) {
                            case 401:
                                message = 'Accès interdit.';
                                break;
                            case 403:
                                message = message =
                                    error?.response?.data?.message ??
                                    "Accès interdit. Vous n'avez pas les droits ou votre session a expiré. Si le problème persiste, merci de recharger votre page.";
                                break;
                            case 404:
                                message = error?.response?.data?.message ?? 'La ressource demandée est introuvable.';
                                break;
                            case 409:
                                message =
                                    error?.response?.data?.message ??
                                    "Un doublon a été détecté et n'a pas été sauvegardé.";
                                break;
                            case 422:
                                message = `<h3>${
                                    error?.response?.data?.message ?? 'Des valeurs envoyées sont incorrectes.'
                                }</h3>`;
                                if (error?.response?.data?.details) {
                                    message += '<ul>';
                                    for (const field in error.response.data.details) {
                                        message += `<li>${field} : ${error.response.data.details[field]}</li>`;
                                    }
                                    message += '</ul>';
                                }
                                break;
                            default:
                                message =
                                    'Une erreur s’est produite. Si le problème persiste, merci de recharger votre page.';
                        }
                        Notify.create({
                            type: 'negative',
                            message: message,
                            html: true,
                            actions: [{ icon: 'close', color: 'white', round: true, handler: () => void 0 }],
                        });
                    }
                }
                return Promise.reject(error);
            },
        );
    },
};
