import { createRouter, createWebHistory } from 'vue-router';

import AdminRoutes from '@/admin/routes/routes';
import { useGlobalStore } from '@/stores/global.store';
import SecurityRoutes from '@/router/security.routes';
import DocRoutes from '@/router/doc.routes';
import AppRoutes from '@/router/app.routes';
import EditorRoutes from '@/router/editor.routes';
import SettingsRoutes from '@/router/settings.routes';
import ProductRoutes from '@/router/solution.routes';
import { RouteNames } from '@/enums/router.enum';

const router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: [
        ...DocRoutes,
        ...SecurityRoutes,
        ...AppRoutes,
        ...EditorRoutes,
        ...SettingsRoutes,
        ...ProductRoutes,
        ...AdminRoutes,
        {
            name: RouteNames.NotFound,
            path: '/:catchAll(.*)*',
            component: () => import('@/pages/ErrorNotFound.vue'),
            meta: { disableLayout: true },
            beforeEnter: () => {
                const globalStore = useGlobalStore();
                globalStore.isReady = true;
            },
        },
    ],
    history: createWebHistory(import.meta.env.BASE_URL),
});

export default router;
