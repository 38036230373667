import { SettingsTabList } from '@/router/settings.routes';
import type { Resource } from '@/models/resource.model';

import type { Products } from '@/enums/product.enum';
import { UserRoles } from '@/enums/role.enum';
import type { RouteLocationRaw } from 'vue-router';
import type { PanelTab } from '@/enums/editor/editor.enum';
import { AppEvents, CustomizationEvent, EditorEvent } from '@/enums/event.enum';
import type { Solutions } from '@/enums/solution/solution.enum';

export interface ApplicationTheme {
    id: number;
    name: string;
    primaryColor: string;
    secondaryColor: string;
    fontUri?: string;
    fontFamily?: string;
    logoId?: number;
    logo?: Resource;
    createdAt: string;
    updatedAt: string;
}

export type Event = {
    [EditorEvent.DocumentScroll]: (height: number) => void;
    [EditorEvent.DocumentEditorPanel]: (panelName: PanelTab, groupName: CustomizationEvent) => void;
    [EditorEvent.DocumentEditorCustomizationItem]: (groupName: CustomizationEvent) => void;
    [AppEvents.SoldPropertyResultMouseEnter]: (soldPropertyLandUnitId: string) => void;
    [AppEvents.SoldPropertyResultMouseLeave]: (soldPropertyLandUnitId: string) => void;
    [AppEvents.SoldPropertiesLandUnitMouseEnter]: (soldPropertyLandUnitId: string) => void;
    [AppEvents.SoldPropertiesLandUnitClick]: (soldPropertyLandUnitId: string) => void;
    [AppEvents.SoldPropertiesLandUnitMouseLeave]: () => void;
};

export enum ApplicationMode {
    Embedded = 'embedded',
    Standalone = 'standalone',
}

export interface AppConfiguration {
    enabledWebhook: boolean;
    hideLeftSidebar: boolean;
    hideSettingTabs: boolean;
    hiddenSettingTabs: SettingsTabList[];
    canCreateAgent: boolean;
    canCreateAgency: boolean;
    canCreateTheme: boolean;
    hideBreadcrumbs: boolean;
    disableDocumentNavigation: boolean;
    denseMode: boolean;
}

export interface AppRoute {
    id?: string;
    icon: string;
    label: string;
    displayInSidebar?: boolean;
    separator?: boolean;
    to: RouteLocationRaw;
    product?: Products;
    roles?: UserRoles[];
    solutions?: Solutions[];
}
