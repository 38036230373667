import { LocationClient } from '@aws-sdk/client-location';
import { type App } from 'vue';
import { LocationClientKey } from '@/injection/keys.injection';

export default {
    install(app: App) {
        const awsHelper = new LocationClient({
            region: import.meta.env.VITE_AWS_LOCATION_REGION,
            credentials: {} as any,
            signer: {
                sign: async (requestToSign) =>
                    Object.assign(requestToSign, {
                        query: {
                            key: import.meta.env.VITE_AWS_LOCATION_API_KEY,
                            ...(requestToSign.query ?? {}),
                        },
                    }),
            },
        });

        app.provide(LocationClientKey, awsHelper);
    },
};
