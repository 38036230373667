import { api } from '@/plugins/axios';
import type { User } from '@/models/user.model';
import type { PublicReseller, Reseller } from '@/models/reseller.model';
import type { Company } from '@/models/company.model';
import type { ApplicationTheme } from '@/models/app.model';
import { HttpStatusCode } from 'axios';
import type { Service } from '@/models/service.model';
import type { AuthKey } from '@/models/authentication.model';

export const fetchReseller = async () => (await api.get<Reseller>('/app/common/reseller')).data;

export const fetchPublicReseller = async () => (await api.get<PublicReseller>('/app/public/reseller')).data;

export const fetchUser = async () => (await api.get<User>('/app/common/user')).data;

export const fetchCompany = async () => (await api.get<Company>('/app/common/company')).data;

export const fetchCompanyAuth = async () => (await api.get<AuthKey>('/app/common/company/auth')).data;

export const fetchCompanyServices = async () => (await api.get<Service[]>('/app/common/company/services')).data;

export const registerUserToWebinar = async (email: string) =>
    (await api.post<User>('/common/register-to-webinar', { email })).data;

export const fetchTheme = async () => (await api.get<ApplicationTheme | null>('/app/common/theme')).data;

export const logout = async () => (await api.get('/app/common/logout')).status === HttpStatusCode.NoContent;
