export enum DocumentType {
    Avl = 'avl',
    Avm = 'avm',
    AvmBusiness = 'avm-business',
    AvlNotary = 'avl-notary',
    AvmNotary = 'avm-notary',
    Location = 'location',
}

export enum DocumentStatus {
    Pending = 'pending',
    Completed = 'completed',
}
