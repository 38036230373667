import type { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '@/enums/router.enum';
import { useGlobalStore } from '@/stores/global.store';

export const routes: RouteRecordRaw[] = [
    {
        path: '/login',
        name: RouteNames.Login,
        components: {
            default: () => import('@/pages/security/SecurityPage.vue'),
        },
        meta: { disableLayout: true, showRecaptcha: true },
    },
    {
        path: '/register',
        name: RouteNames.Register,
        components: {
            default: () => import('@/pages/security/SecurityPage.vue'),
        },
        meta: { disableLayout: true, showRecaptcha: true },
    },
    {
        path: '/forgotten-password',
        name: RouteNames.ForgottenPassword,
        components: {
            default: () => import('@/pages/security/SecurityPage.vue'),
        },
        meta: { disableLayout: true, showRecaptcha: true },
    },
    {
        path: '/edit-password',
        name: RouteNames.EditPassword,
        components: {
            default: () => import('@/pages/security/SecurityPage.vue'),
        },
        meta: { disableLayout: true, showRecaptcha: true },
    },
    {
        path: '/show-credentials',
        name: RouteNames.ShowCredentials,
        components: {
            default: () => import('@/pages/security/SecurityPage.vue'),
        },
        meta: { disableLayout: true, showRecaptcha: true },
    },
].map((route) => ({
    ...route,
    beforeEnter: () => {
        const globalStore = useGlobalStore();
        globalStore.isReady = true;
    },
}));

export default routes;
