import { VueReCaptcha } from 'vue-recaptcha-v3';
import type { App } from 'vue';

export default {
    install(app: App) {
        app.use(VueReCaptcha, {
            siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
            loaderOptions: {},
        });
    },
};
