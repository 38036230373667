import { defineStore, storeToRefs } from 'pinia';
import { LocalStorage, SessionStorage, type WebStorageGetMethodReturnType } from 'quasar';
import { StorageKeys } from '@/models/storage.model';
import { useGlobalStore } from '@/stores/global.store';
import { ApplicationMode } from '@/models/app.model';

export const useStorageStore = defineStore('storage', () => {
    const globalStore = useGlobalStore();
    const { appMode } = storeToRefs(globalStore);

    function get<K = WebStorageGetMethodReturnType>(key: string): K {
        if (appMode.value === ApplicationMode.Embedded) {
            return SessionStorage.getItem(key) as K;
        } else {
            return LocalStorage.getItem(key) as K;
        }
    }

    function has(key: StorageKeys) {
        if (appMode.value === ApplicationMode.Embedded) {
            return SessionStorage.has(key);
        } else {
            return LocalStorage.has(key);
        }
    }

    function set(key: StorageKeys, value: any) {
        if (appMode.value === ApplicationMode.Embedded) {
            SessionStorage.set(key, value);
        } else {
            LocalStorage.set(key, value);
        }
    }

    function remove(key: StorageKeys) {
        if (appMode.value === ApplicationMode.Embedded) {
            SessionStorage.remove(key);
        } else {
            LocalStorage.remove(key);
        }
    }

    return {
        get,
        set,
        has,
        remove,
    };
});
