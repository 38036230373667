import { useAuthenticationStore } from '@/stores/authentication.store';
import { UserRoles } from '@/enums/role.enum';

export const useRoles = () => {
    const authenticationStore = useAuthenticationStore();

    function isGranted(role: UserRoles) {
        return authenticationStore.roles?.includes(role) || false;
    }

    function hasAtLeast(roles?: UserRoles[]) {
        return roles?.length > 0 ? authenticationStore.roles.some((role) => roles.includes(role)) : true;
    }

    return {
        isGranted,
        hasAtLeast,
    };
};
