import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { addAuthorizationHeader, handleMultipleRefreshTokenRequests } from '@/utils/axios.utils';
import { useGlobalStore } from '@/stores/global.store';
import { ApplicationMode } from '@/models/app.model';
import { Notify } from 'quasar';
import { buildAuthorizationHeaderValue } from '@/utils/authentication.utils';
import axios, { type AxiosError, HttpStatusCode } from 'axios';
import router from '@/router';
import { RouteNames } from '@/enums/router.enum';

const api = axios.create({ baseURL: `${import.meta.env.VITE_API_BASE_URL}` });

if (import.meta.env.MODE === 'development') {
    api.interceptors.request.use((config) => {
        const authenticationStore = useAuthenticationStore();

        if (
            (router.currentRoute.value?.name as string)?.startsWith('admin-') &&
            router.currentRoute.value?.name !== RouteNames.Login
        ) {
            config.baseURL = `${import.meta.env.VITE_API_BASE_URL}`;
        }

        if (authenticationStore.accessToken && !config.removeAuthorization) {
            config.headers.Authorization = buildAuthorizationHeaderValue(authenticationStore.accessToken);
        }
        return config;
    });
}

export default {
    install() {
        const authenticationStore = useAuthenticationStore();
        const globalStore = useGlobalStore();
        const { applicationError, appMode } = storeToRefs(globalStore);
        const { accessToken } = storeToRefs(authenticationStore);
        const isTokenRefreshing = ref(false);
        const refreshTokenError = ref(false);

        const abortController = new AbortController();

        watch(
            () => authenticationStore.impersonate,
            (value) => {
                if (value) {
                    console.log('[DEBUG|AXIOS] Impersonate id set - Updating axios headers');
                    api.defaults.headers.common['X-Switch-User'] = value;
                }
            },
            { immediate: true },
        );

        watch(
            accessToken,
            () => {
                api.interceptors.request.use((config) => {
                    const authenticationStore = useAuthenticationStore();

                    if (
                        (router.currentRoute.value?.name as string)?.startsWith('admin-') &&
                        router.currentRoute.value?.name !== RouteNames.Login
                    ) {
                        config.baseURL = `${import.meta.env.VITE_API_BASE_URL}`;
                    }

                    if (authenticationStore.accessToken && !config.removeAuthorization) {
                        config.headers.Authorization = buildAuthorizationHeaderValue(authenticationStore.accessToken);
                    } else if (config.removeAuthorization) {
                        delete config.headers.Authorization;
                    }

                    return config;
                });

                api.interceptors.response.use(
                    (res) => res,
                    async (axiosError: AxiosError) => {
                        if (!axiosError.response) {
                            return Promise.reject(axiosError);
                        }

                        // Error while refreshing token
                        if (axiosError.config.params?.refresh_token) {
                            refreshTokenError.value = true;
                        } else if (
                            !refreshTokenError.value &&
                            axiosError.response.status === HttpStatusCode.Unauthorized
                        ) {
                            if (!isTokenRefreshing.value) {
                                console.log('[DEBUG|AUTH] Refreshing access token');
                                isTokenRefreshing.value = true;
                                delete api.defaults.headers.common['Authorization'];
                                try {
                                    const authResult = await authenticationStore.handleAccessTokenRefresh(
                                        authenticationStore.refreshToken,
                                    );

                                    addAuthorizationHeader(api, authResult.token);
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                } catch (e) {
                                    abortController.abort();
                                    if (appMode.value === ApplicationMode.Standalone) {
                                        authenticationStore.$reset();
                                        Notify.create({
                                            type: 'warning',
                                            message: "Une erreur s'est produite, veuillez vous reconnecter",
                                            textColor: 'white',
                                            actions: [
                                                { icon: 'close', color: 'white', round: true, handler: () => void 0 },
                                            ],
                                        });
                                        await router.replace({ name: RouteNames.Login });
                                        return;
                                    } else {
                                        applicationError.value = 'Session expirée, veuillez vous reconnecter';
                                    }
                                } finally {
                                    isTokenRefreshing.value = false;
                                }
                            }

                            return handleMultipleRefreshTokenRequests(
                                api,
                                isTokenRefreshing,
                                axiosError,
                                accessToken,
                                abortController,
                            );
                        }

                        return Promise.reject(axiosError);
                    },
                );
            },
            { immediate: true },
        );
    },
};

export { api };
