<script setup lang="ts">
defineModel<any>({ required: false });

defineProps({
    loading: { type: Boolean, required: false, default: true },
    loadingProgress: { type: Number, required: false, default: () => null },
    loaderColor: { type: String, required: false, default: () => 'primary' },
    loaderSize: { type: String, required: false, default: () => '3em' },
});
</script>

<template>
    <div
        v-if="loading"
        class="overlay-container flex column justify-center items-center self-center full-height full-width q-my-auto"
    >
        <q-spinner :color="loaderColor" :size="loaderSize" />
        <div class="center-container column items-center">
            <q-linear-progress
                v-if="loadingProgress !== null"
                :value="loadingProgress"
                :animation-speed="200"
                class="q-mt-lg"
            />
        </div>
        <slot name="loading"></slot>
    </div>
    <slot v-if="!loading"></slot>
</template>

<style scoped lang="scss">
.center-container {
    width: 25%;
}
</style>
