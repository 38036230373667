<script setup lang="ts">
import { computed, ref } from 'vue';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { useGlobalStore } from '@/stores/global.store';

const authenticationStore = useAuthenticationStore();
const globalStore = useGlobalStore();

function onImpersonateLogoutClick() {
    authenticationStore.setImpersonate();
    window.location.reload();
}

const expandBanner = ref(true);
const userName = computed(() => `${globalStore.user.name} #${authenticationStore.impersonate}`);
</script>

<template>
    <div
        v-if="authenticationStore.impersonate"
        class="impersonation-banner absolute-top z-top row justify-center"
        :class="{ small: !expandBanner }"
    >
        <q-card class="bg-negative q-px-sm q-py-xs">
            <div class="row items-center no-wrap q-col-gutter-x-md">
                <div class="col-auto">
                    <q-btn color="white" icon="sym_o_warning" flat round dense @click="expandBanner = !expandBanner">
                        <q-tooltip class="bg-black text-body2">
                            <template v-if="expandBanner">Réduire la barre</template>
                            <template v-else>Agrandir la barre</template>
                        </q-tooltip>
                    </q-btn>
                </div>
                <div v-if="expandBanner" class="text-white">
                    Connecté en tant que
                    <strong>{{ userName }}</strong>
                </div>
                <div class="col-auto">
                    <q-btn
                        color="white"
                        icon="sym_o_exit_to_app"
                        :label="expandBanner ? 'Quitter' : ''"
                        flat
                        dense
                        @click="onImpersonateLogoutClick"
                    />
                </div>
            </div>
        </q-card>
    </div>
</template>
