import type { PublicReseller, Reseller } from '@/models/reseller.model';
import { getCssVar, setCssVar } from 'quasar';
import { useHead } from 'unhead';

export const setupInterface = (reseller?: PublicReseller | Reseller) => {
    if (reseller && 'theme' in reseller && reseller?.theme) {
        setCssVar('primary', reseller.theme.primaryColor);
        setCssVar('secondary', reseller.theme.secondaryColor);
    }

    const prefix = reseller?.meta?.prefix || 'default';

    useHead({
        title: reseller?.meta?.title || 'Modelo Insight',
        meta: [
            {
                name: 'description',
                content: reseller?.meta?.description || 'Modelo Insight By Septeo',
            },
            {
                name: 'msapplication-TileColor',
                content: reseller?.meta?.description || 'Modelo Insight By Septeo',
            },
            { name: 'msapplication-TileColor', content: getCssVar('secondary') },
            { name: 'theme-color', content: getCssVar('secondary') },
        ],
        link: [
            { rel: 'apple-touch-icon', sizes: '180x180', href: `/icons/${prefix}/apple-touch-icon.png` },
            { rel: 'icon', sizes: '32x32', href: `/icons/${prefix}/favicon-32x32.png` },
            { rel: 'icon', sizes: '16x16', href: `/icons/${prefix}/favicon-16x16.png` },
            { rel: 'manifest', href: `/icons/${prefix}/site.webmanifest` },
            { rel: 'manifest', href: `/icons/${prefix}/safari-pinned-tab.svg`, color: getCssVar('secondary') },
        ],
    });
};
