import type { RouteRecordRaw } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { checkSidebarQueryParam, checkUserAuthentication } from '@/utils/router.utils';
import { RouteNames } from '@/enums/router.enum';

export const routes: RouteRecordRaw[] = [
    {
        path: '/solutions',
        name: RouteNames.Solutions,
        children: [
            {
                path: '',
                name: RouteNames.SolutionLanding,
                components: {
                    header: () => import('@/components/layout/headers/SolutionHeader.vue'),
                    default: () => import('@/pages/solution/SolutionPage.vue'),
                },
            },
            {
                path: 'user-guide',
                name: RouteNames.SolutionUserGuide,
                components: {
                    header: () => import('@/components/layout/headers/SolutionHeader.vue'),
                    default: () => import('@/pages/solution/UserGuidePage.vue'),
                },
            },
            {
                path: ':id(\\d+)/:tab?',
                name: RouteNames.SolutionDetails,
                components: {
                    header: () => import('@/components/layout/headers/SolutionHeader.vue'),
                    default: () => import('@/pages/solution/SolutionPage.vue'),
                },
            },
            {
                path: ':id(\\d+)/:tab?/:innerId(\\d+)',
                name: RouteNames.SolutionDetailsInner,
                components: {
                    header: () => import('@/components/layout/headers/SolutionHeader.vue'),
                    default: () => import('@/pages/solution/SolutionPage.vue'),
                },
            },
        ],
    },
].map(
    (route) =>
        ({
            ...route,
            beforeEnter: multiguard([checkSidebarQueryParam, checkUserAuthentication]),
        }) as RouteRecordRaw,
);

export default routes;
