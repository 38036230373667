import type { RouteRecordRaw } from 'vue-router';
import { checkSidebarQueryParam, checkUserAuthentication } from '@/utils/router.utils';

import { RouteNames } from '@/enums/router.enum';
import multiguard from 'vue-router-multiguard';

export enum SettingsTabList {
    Themes = 'themes',
    Agencies = 'agencies',
    Agents = 'agents',
    CustomerProperties = 'properties',
    Templates = 'templates',
    Reviews = 'customer-reviews',
    Subscription = 'subscription',
    Users = 'users',
}

export const routes: RouteRecordRaw[] = [
    {
        path: '/settings',
        name: RouteNames.Settings,
        children: [
            {
                path: '',
                name: 'app-settings-landing',
                redirect: { name: RouteNames.SettingsThemesPrintList },
            },
            {
                path: SettingsTabList.Themes,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsThemesPrintList,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/themes/ThemeList.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: RouteNames.SettingsThemesPrintCreate,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/themes/ThemeEdit.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: RouteNames.SettingsThemesPrintEdit,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/themes/ThemeEdit.vue'),
                        },
                    },
                ],
            },
            {
                path: SettingsTabList.Agencies,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsAgenciesList,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/agency/AgencyList.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: RouteNames.SettingsAgenciesCreate,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/agency/AgencyEdit.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: RouteNames.SettingsAgenciesEdit,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/agency/AgencyEdit.vue'),
                        },
                    },
                ],
            },
            {
                path: SettingsTabList.Agents,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsAgentsList,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/agent/AgentList.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: RouteNames.SettingsAgentsCreate,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/agent/AgentEdit.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: RouteNames.SettingsAgentsEdit,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/agent/AgentEdit.vue'),
                        },
                    },
                ],
            },
            {
                path: SettingsTabList.Templates,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsTemplatesList,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/template/TemplateList.vue'),
                        },
                    },
                ],
            },
            {
                path: SettingsTabList.CustomerProperties,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsPropertiesList,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/properties/CompanyPropertiesPage.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: RouteNames.SettingsPropertiesCreate,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/properties/CompanyPropertyCreationPage.vue'),
                        },
                    },
                ],
            },
            {
                path: `${SettingsTabList.Reviews}/:reviewTab?`,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsReviewsLanding,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/reviews/ReviewsConfigurationPage.vue'),
                        },
                    },
                ],
            },
            {
                path: `${SettingsTabList.Subscription}/:tab?`,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsSubscription,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/subscription/SubscriptionPage.vue'),
                        },
                    },
                ],
            },
            {
                path: SettingsTabList.Users,
                children: [
                    {
                        path: '',
                        name: RouteNames.SettingsUsersList,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/users/UserList.vue'),
                        },
                    },
                    {
                        path: 'create',
                        name: RouteNames.SettingsUsersCreate,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/users/UserList.vue'),
                        },
                    },
                    {
                        path: ':id(\\d+)',
                        name: RouteNames.SettingsUsersEdit,
                        components: {
                            header: () => import('@/components/layout/headers/SettingsHeader.vue'),
                            default: () => import('@/pages/settings/users/UserList.vue'),
                        },
                    },
                ],
            },
        ],
    },
].map((route) => ({ ...route, beforeEnter: multiguard([checkSidebarQueryParam, checkUserAuthentication]) }));

export default routes;
