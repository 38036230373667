import { colors, getCssVar, setCssVar } from 'quasar';
import { type DocumentTheme, type DocumentThemePayload } from '@/models/editor/document/theme.model';
import { useGlobalStore } from '@/stores/global.store';
import { api } from '@/plugins/axios';
import lighten = colors.lighten;
import { ThemeCoverVariants, ThemeNotaryVariant, ThemeVariants } from '@/enums/editor/document/theme.enum';

export const setDocumentStyle = (theme: DocumentTheme | DocumentThemePayload, element?: Element) => {
    const globalStore = useGlobalStore();

    if (theme.primaryColor) {
        setCssVar('document-primary-color', theme.primaryColor, element);
        setCssVar('document-primary-color-light', lighten(theme.primaryColor, 90), element);
    }
    if (theme.secondaryColor) {
        setCssVar('document-secondary-color', theme.secondaryColor, element);
        setCssVar('document-secondary-color-light', lighten(theme.secondaryColor, 90), element);
    }
    if (theme.titleColor) {
        setCssVar('document-title-color', theme.titleColor, element);
    }
    if (theme.bodyFont && typeof theme.bodyFont === 'object') {
        setCssVar('document-body-font', theme.bodyFont.fontFamily, element);
        globalStore.addStylesheet(theme.bodyFont);
    }
    if (theme.titleFont && typeof theme.titleFont === 'object') {
        setCssVar('document-title-font', theme.titleFont.fontFamily, element);
        globalStore.addStylesheet(theme.titleFont);
    }
};

export const addHeaderStylesheet = (href: string) => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = href;

    document.head.appendChild(link);

    return link;
};

export const getAppThemeStyle = () => ({
    '--q-document-primary-color': getCssVar('primary'),
    '--q-document-secondary-color': getCssVar('secondary'),
});

export const getThemeLogoUri = (themeId: number | null, logoType: string): string => {
    return themeId ? `${api.getUri()}/document-themes/${themeId}/${logoType}/image` : '';
};

export const getThemesList = (isCover: boolean) => {
    const globalStore = useGlobalStore();
    const themes = isCover ? [...ThemeCoverVariants] : [...ThemeVariants];
    if (globalStore.isNotary) {
        themes.push(ThemeNotaryVariant);
    }
    return themes;
};
